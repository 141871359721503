import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { Menu, Layout, message } from 'antd';
import { PageHeaderWrapper } from "@ant-design/pro-layout";
import DictionaryTable from "./dictionary"
import { find, orderBy } from "lodash";
import Scrollbar from "react-scrollbars-custom";

const { Sider, Content } = Layout;

type DictionaryDefinition = {
  name: string
  path: string
}

const DICTIONARIES : DictionaryDefinition[] = orderBy([
  { path: "activity_categories", name: "Activitats: Categoríes" },
  { path: "activity_goals", name: "Activitats: Objectius" },
  { path: "activity_types", name: "Activitats: Tipus" },
  { path: "materials", name: "Materials" },
  // { path: "spaces", name: "Espais" },
  { path: "cancel_reasons", name: "Activitats: Motius de baixa" }
], ["name", "asc"]);

const DictionariesSection: React.FC = () => {
  const history = useHistory();

  const { dictionary: path } = useParams<{ dictionary: string}>();

  const dictionary = find(DICTIONARIES, { path });

  const handleClick = (menuItem) => {
    const dict = find(DICTIONARIES, { path: menuItem.key} );

    if (dict) {
      history.push(`/preferences/dictionaries/${dict.path}`);
    } else {
      history.push('/preferences/dictionaries');
      message.error("El diccionari que intentes modificar no existeix");
    }
  };

  const menuItems = DICTIONARIES.map((dict) => ({ key: dict.path, label: dict.name }));

  return (
    <PageHeaderWrapper>
      <Layout>
        <Sider theme="light" width={300}>
          <Scrollbar style={{ width: 290, height: "calc(100vh - 225px)" }}>
            <Menu onClick={handleClick} mode="inline" items={menuItems} defaultSelectedKeys={dictionary ? [dictionary.path] : []} />
          </Scrollbar>
        </Sider>
        <Content style={{ padding: '0 50px' }}>
          {
            dictionary
            ? <DictionaryTable path={dictionary.path} title={dictionary.name} />
            : <span>Selecciona un diccionari de la llista</span>
          }
        </Content>
      </Layout>
    </PageHeaderWrapper>
  );
};

export default DictionariesSection;
